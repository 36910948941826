@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

:root {
    --amplify-primary-color: #cf012c;
    --amplify-primary-tint: #cf012c;
    --amplify-primary-shade: #cf012c;
}