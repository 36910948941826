@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

:root {
    --amplify-primary-color: #cf012c;
    --amplify-primary-tint: #cf012c;
    --amplify-primary-shade: #cf012c;
}
.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        -webkit-animation: App-logo-spin infinite 20s linear;
                animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #141414;
}

.App-link {
    color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
#container-circles {
    position: absolute;
    left: 50%;
    bottom: 90px
}

#outer-circle {
    left: -37px;
    height: 75px;
    width: 75px;
    background-color: hsla(0, 0%, 100%, .4);
    z-index: 1
}

#inner-circle,
#outer-circle {
    position: absolute;
    border-radius: 50%
}

#inner-circle {
    left: 50%;
    top: 38px;
    height: 44px;
    width: 44px;
    background: #CF012C;
    margin: -22px 0 0 -22px;
    z-index: 2
}

#inner-circle.is-clicked {
    height: 38px;
    width: 38px;
    margin: -19px 0 0 -19px
}

#white-flash.normal {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 1;
    transition: opacity .9s ease-out
}

#white-flash.do-transition {
    opacity: 0;
    background: #fff
}

#display-error {
    color: #000;
    background-color: #fff
}

.react-html5-camera-photo {
    position: relative;
    text-align: center
}

.react-html5-camera-photo>img,
.react-html5-camera-photo>video {
    width: 768px
}

.react-html5-camera-photo>.display-error {
    width: 768px;
    margin: 0 auto
}

@media(max-width:768px) {

    .react-html5-camera-photo>.display-error,
    .react-html5-camera-photo>img,
    .react-html5-camera-photo>video {
        width: 100%
    }
}

.react-html5-camera-photo-fullscreen>img,
.react-html5-camera-photo-fullscreen>video {
    width: calc(100vw - 90px) !important;
    height: calc(100vh - 90px) !important;
}

.react-html5-camera-photo-fullscreen>video {
    object-fit: fill
}

.react-html5-camera-photo-fullscreen>.display-error {
    width: 100vw;
    height: 100vh
}
